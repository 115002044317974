import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import arKeys from './Languages/ar.json';
import enKeys from './Languages/en.json';

const urlLang = window.location.pathname.split('/')[1]?.toLowerCase();
export const languages = {
  ar: { label: 'العربية', isRTL: true },
  en: { label: 'English', isRTL: false },
};

export const lang = Object.keys(languages).includes(urlLang)
  ? urlLang
  : Object.keys(languages)[0];
export const { isRTL } = languages[lang];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: { ...enKeys },
      },
      ar: {
        translation: { ...arKeys },
      },
    },
    lng: lang,
    fallbackLng: 'ar',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
