import axios from 'axios';
import { generatePathWithServerURL } from './helper';
import { lang } from './I18n/I18n';

function removeAuthentication() {
  localStorage.removeItem('userId');
  localStorage.removeItem('accessToken');
}
const handle401 = (statusCode) => {
  if (localStorage.getItem('accessToken') && statusCode === 401) {
    removeAuthentication();
    window.location.href = '/';
  }
};

const handle404 = (statusCode) => {
  if (statusCode === 404) {
    window.location.href = '/page-not-found';
  }
};

const instance = axios.create({
  baseURL: generatePathWithServerURL('/api'),
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');

  return {
    ...config,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      ...config.headers,
      ...(accessToken ? { 'x-csrf-token': accessToken } : {}),
      'Accept-Language': lang,
    },
  };
});

instance.interceptors.response.use(
  response => response,
  (error) => {
    handle401(error.response && error.response.status);
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  (error) => {
    handle404(error.response && error.response.status);
    return Promise.reject(error);
  },
);

export default instance;
