import React, { lazy, useMemo, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { createBrowserHistory } from 'history';
import ReactNotification from 'react-notifications-component';
import { lang, languages } from './util/I18n/I18n';
import 'dayjs/locale/ar';
import SocialMedia from './components/UIElements/SocialMedia/SocialMedia';
import CookiesBar from './components/CookiesBar/CookiesBar';
import 'react-notifications-component/dist/theme.css';
import AnalyticsInit from './AnalyticsInit';
import Spinner from './components/UIElements/Spinner/Spinner';
import { useFetchPostCategories } from './store/actions/postCategoriesActions';
import ErrorBoundary from './Pages/ErrorBoundary/ErrorBoundary';

const Layout = lazy(() => import('./components/Layout/Layout'));
const Home = lazy(() => import('./Pages/Home'));
const ComingSoon = lazy(() => import('./Pages/ComingSoon/ComingSoon'));
const Profile = lazy(() => import('./Pages/Profile/Profile'));
const ViewPostsList = lazy(() => import('./Pages/ViewPostsList/ViewPostsList'));
const ViewPost = lazy(() => import('./Pages/ViewPost/ViewPost'));
const ToggleSignup = lazy(() => import('./Pages/ToggleSignup/ToggleSignup'));
const VerifyVisitorMail = lazy(() => import('./Pages/VerifyVisitorMail/VerifyVisitorMail'));
const ForgetPassword = lazy(() => import('./Pages/Login/ForgetPassword/ForgetPassword'));
const ChangePassword = lazy(() => import('./components/ProfileInfo/changePassword/changePassword'));
const ViewPage = lazy(() => import('./Pages/ViewPage/ViewPage'));
const ContactUs = lazy(() => import('./Pages/ContactUs/ContactUs'));
const Verification = lazy(() => import('./Pages/Login/ResetPassword/ResetPassword'));
const ComplaintDetails = lazy(() => import('./Pages/ComplaintDetails/ComplaintDetails'));
const NotFound = lazy(() => import('./Pages/NotFound/NotFound'));
const Search = lazy(() => import('./Pages/Search/Search'));

if (window.location.pathname === '/') {
  window.location.replace(`/${lang}`);
}

dayjs.locale(lang);

document
  .getElementsByTagName('html')[0]
  .setAttribute('dir', languages[lang].isRTL ? 'rtl' : 'ltr');

const App = () => {
  const { t } = useTranslation();
  useMemo(() => {
    document.title = t('metaInfo.title');
  }, [t]);
  useFetchPostCategories();

  return (
    <ErrorBoundary>
      <ReactNotification />
      <SocialMedia />
      <Router
        onUpdate={() => window.scrollTo(0, 0)}
        history={createBrowserHistory({ basename: lang })}
      >
        <Suspense fallback={<Spinner spinnerText={t('message.loading')} />}>
          <Layout>
            <AnalyticsInit />
            <Switch>
              <Route path="/" exact component={() => <Home showSlider />} />
              <Route path="/forgetpassword" exact component={ForgetPassword} />
              <Route path="/changepassword" exact component={ChangePassword} />
              <Route
                path="/resetpassword/:accessToken/:userId"
                exact
                component={Verification}
              />
              <Route path="/profile/:view" exact component={Profile} />
              <Route path="/coming" exact component={ComingSoon} />
              <Route path="/search" exact component={Search} />
              <Route path="/login" exact component={ToggleSignup} />
              <Route path="/verify-mail" exact component={VerifyVisitorMail} />
              <Route path="/signup" exact component={ToggleSignup} />
              <Route
                path="/archive/:postType/:categoryId/:title"
                exact
                component={ViewPostsList}
              />
              <Route
                path="/posts/:postType/:postId/:postTitle"
                exact
                component={ViewPost}
              />
              <Route
                path="/:pageTitle/:pageId/:sectionId"
                exact
                component={ViewPage}
              />
              <Route path="/contactus" exact component={ContactUs} />
              <Route
                path="/complaints/:complaintId"
                exact
                component={ComplaintDetails}
              />
              <Route path="/page-not-found" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </Suspense>
      </Router>
      <CookiesBar />
    </ErrorBoundary>
  );
};

export default App;
