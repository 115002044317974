const compose = (f1, f2) => data => f1(f2(data));

const Wrapper = input => ({
  map: f => Wrapper(f(input)),
  chain: f => f(input),
  fold: f => f(input),
});
const Right = x => ({
  map: f => Right(f(x)),
  chain: f => f(x),
  fold: (_, g) => g(x),
});
const Left = (x, error) => ({
  map: () => Left(x, error),
  chain: f => Left(x, error),
  fold: (f, _) => f(x, error),
});

const fromNullable = (x, error) => (x != null ? Right(x) : Left(x, error));

export {
  compose, Wrapper, Right, Left, fromNullable,
};
