import React from 'react';
import { DotLoader } from 'react-spinners';
import styles from './Spinner.module.scss';

const Spinner = ({ spinnerText }) => (
  <div className={styles['loading-spinner']}>
    <div className={styles.spinner}>
      <DotLoader
        sizeUnit="px"
        size={60}
        color="#FCCD05"
        loading
      />
      <span>{spinnerText}</span>
    </div>
  </div>
);

export default Spinner;
