import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import SETTINGS_APIS from '../../../APIs/settings_apis';
import { isRTL } from '../../../util/I18n/I18n';
import styles from './SocialMedia.module.scss';

const socialMediaMetaData = {
  facebook: { name: 'Facebook', icon: 'facebook', className: 'facebook' },
  instagram: { name: 'Instagram', icon: 'instagram', className: 'instagram' },
  linkedIn: { name: 'LinkedIn', icon: 'linkedin', className: 'linkedin' },
  twitter: { name: 'Twitter', icon: 'x-twitter', className: 'twitter' },
  youtube: { name: 'Youtube', icon: 'youtube', className: 'youtube' },
};

const SocialMedia = () => {
  const [socialMediaData, setSocialMediaData] = useState({});

  useEffect(() => {
    (async () => {
      const socialMediaResponse = await SETTINGS_APIS.getSetting({
        where: { name: 'socialMedia' },
      });
      setSocialMediaData(socialMediaResponse[0].data);
    })();
  }, []);

  return (
    <div
      className={`${styles['social-media-container']} ${
        isRTL ? styles['social-media-container-RTL'] : ''
      }`}
    >
      {!_isEmpty(socialMediaData) && Object.entries(socialMediaData).map(([key, value]) => (
        <a key={key} href={value} target="_blank" rel="noopener noreferrer" aria-label={`${socialMediaMetaData[key].name} Page`}>
          {socialMediaMetaData[key].name === 'Twitter'
            ? <i className={`fa-brands fa-${socialMediaMetaData[key].icon} ${styles[socialMediaMetaData[key].className]}`} />
            : <i className={`fa fa-${socialMediaMetaData[key].icon} ${styles[socialMediaMetaData[key].className]}`} />
        }
        </a>
      )) }
    </div>
  );
};

export default SocialMedia;
