import axios from '../util/axios-instance';

const SETTINGS_APIS = {
  getSetting: filter => axios.get('/settings', {
    params: {
      filter: {
        ...filter,
      },
    },
  }).then(response => response.data),
};

export default SETTINGS_APIS;
