import { useDispatch } from 'react-redux';
import { setPostsCategories } from './actionCreators';
import CATEGORIES_APIS from '../../APIs/categories_apis';

export const useFetchPostCategories = async () => {
  const dispatch = useDispatch();
  try {
    const categories = await CATEGORIES_APIS.getAllCategories();
    dispatch(setPostsCategories(categories));
  } catch (error) {
    console.warn(error.message);
  }
};
