import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import styles from './CookiesBar.module.scss';

const CookiesBar = () => {
  const { t } = useTranslation();

  return (
    <CookieConsent buttonText={t('general.cookieButton')} disableStyles containerClasses={styles.container}>
      <strong>{t('general.cookieConsent')}</strong>
    </CookieConsent>
  );
};

export default CookiesBar;
