import { combineReducers } from 'redux';

const initialState = {
  user: {
    userData: JSON.parse(localStorage.getItem('userData')),
    accessToken: localStorage.getItem('accessToken'),
  },
  postsCategories: [],
};

function user(state = initialState.user, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return action.payload;
    case 'SET_SIGNIN_DATA':
      return action.payload;
    case 'REMOVE_USER_DATA':
      return action.payload;
    default:
      return state;
  }
}

function postsCategories(state = initialState.postsCategories, action) {
  switch (action.type) {
    case 'SET_POSTS_CATEGORIES':
      return action.payload;

    default:
      return state;
  }
}

const reducers = combineReducers({ user, postsCategories });

export default reducers;
