import axios from '../util/axios-instance';

const CATEGORIES_APIS = {
  getAllCategories: (filter, cancelToken) => axios.get('/categories', {
    params: {
      filter: {
        ...filter,
      },
    },
    cancelToken,
  }).then(response => response.data),
  getCategoryPosts: (id, filter) => axios.get(`/categories/${id}/posts`, {
    params: {
      filter: {
        ...filter,
      },
    },
  }).then(response => response.data),
  postNewCategory: data => axios.post('/categories', data).then(response => response.data),
};

export default CATEGORIES_APIS;
