import React from 'react';
import ReactDOM from 'react-dom';
import { isIE } from 'react-device-detect';

import { Provider } from 'react-redux';
import store from './components/store';
import './index.css';
import App from './App';
import UnSupportedBrowser from './Pages/UnSupportedBrowser/UnSupportedBrowser';
import * as serviceWorker from './serviceWorker';
import 'leaflet/dist/leaflet.css';

const app = isIE ? <UnSupportedBrowser /> : <App />;
ReactDOM.render(
  <Provider store={store}>{app}</Provider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
