import React, { Component } from 'react';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      // error,
      errorInfo,
    });
  }

  render() {
    const {
      // error,
      errorInfo,
    } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      // Error path
      return (
        <div className={styles['error-boundary']}>
          <img alt="mof-logo" src="/images/logo.png" />
          <h2>Something went wrong</h2>
          <h1>Please reload the page</h1>
          {/* <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details> */}
        </div>
      );
    }

    // Normally, just render children
    return children;
  }
}


export default ErrorBoundary;
