import {
  SET_USER_DATA, REMOVE_USER_DATA, SET_SIGNIN_DATA, SET_POSTS_CATEGORIES,
} from '../constants';

const createAction = type => payload => ({
  type,
  payload,
});


export const setUser = createAction(SET_USER_DATA);
export const setSignin = createAction(SET_SIGNIN_DATA);
export const removeUser = createAction(REMOVE_USER_DATA);

export const setPostsCategories = createAction(SET_POSTS_CATEGORIES);
