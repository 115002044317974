import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import styles from './UnSupportedBrowser.module.css';

const supportedBrowsers = ['firefox', 'chromium', 'opera', 'chrome', 'edge'];

const SupportedBrowserUI = ({ browser }) => {
  const { t } = useTranslation();

  return (
    <>
      <div key={browser} className={styles.browser}>
        <img
          src={`/images/${browser}.png`}
          className={styles.browserImage}
          alt={`Supported browser ${browser}`}
        />
        <span className={styles.browserName}>{t(`general.${browser}`)}</span>
      </div>
      <ScrollToTopOnMount />
    </>
  );
};

const UnSupportedBrowser = () => {
  const { t } = useTranslation();

  const supportedBrowsersJSX = supportedBrowsers.map(browser => (
    <SupportedBrowserUI browser={browser} />
  ));

  return (
    <div className={styles.wrapper}>
      <img
        className={styles['mof-logo']}
        src="/images/logo.png"
        alt="Ministry of Finance Logo"
      />
      <h1>{t('general.UnSupportedBrowser')}</h1>
      <p className={styles.subheading}>{t('general.supportedBrowsers')}</p>
      <div className={styles.browsersContainer}>{supportedBrowsersJSX}</div>
    </div>
  );
};
export default UnSupportedBrowser;
